import React, { useMemo } from 'react';

import { useMutation, useQuery } from '@apollo/client';
import { Field, Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import { meshGatewayClient } from 'src/apollo/client';
import { NavigationState } from 'src/utils/typedefs/navigation';
import * as Yup from 'yup';

import APIErrorMessage from '../api-error-message/api-error-message';
import Breadcrumbs from '../breadcrumbs/breadcrumbs';
import Button from '../button/button';
import FormErrorMessage from '../form-error-message/form-error-message';
import { DateField, DropdownField, TextField } from '../formik-fields/formik-fields';
import InputLabel from '../input-label/input-label';
import Panel from '../panel/panel';
import { ADD_ACTIVITY_POINTS, GET_PERSON_BY_EXTERNAL_ID } from '../players/players-queries';
import RadioButtonGroup from '../radio-button-group/radio-button-group';
import Spinner from '../spinner/spinner';
import { H3 } from '../typography/typography';
import * as styles from './adjust-points.module.less';

enum BallColor {
  RED = 'Red',
  ORANGE = 'Orange',
  GREEN = 'Green',
}

enum Methods {
  ADD = 'Add points',
  REMOVE = 'Remove points',
}

interface Props {
  id?: string;
}

const MinusTopGrid = ({ children }) => {
  return <div className={styles.minusTop}>{children}</div>;
};

const AdjustPoints: React.FC<Props> = ({ id }) => {
  const { t } = useTranslation();

  const colors = useMemo(
    () => [
      { label: t('red'), value: BallColor.RED },
      { label: t('orange'), value: BallColor.ORANGE },
      { label: t('green'), value: BallColor.GREEN },
    ],
    [t],
  );

  const methods = useMemo(
    () => [
      { label: t('add points'), value: Methods.ADD },
      { label: t('remove points'), value: Methods.REMOVE },
    ],
    [t],
  );

  const initialValues = {
    adjustPointsMethod: Methods.ADD,
    timestamp: new Date(),
    ballColor: BallColor.RED,
    playPoints: '',
    singlesWinPoints: '',
    doublesWinPoints: '',
    reason: '',
  };

  const [addActivityPoints, { loading, error: addActivityPointsError, data }] = useMutation(ADD_ACTIVITY_POINTS, {
    client: meshGatewayClient,
  });

  const { data: profileData, loading: loadingProfile } = useQuery(GET_PERSON_BY_EXTERNAL_ID, {
    client: meshGatewayClient,
    variables: { externalId: id },
  });

  const onSubmit = async (values) => {
    const points = {
      playPoints: 0,
      singlesPoints: 0,
      doublesPoints: 0,
    };

    if (values?.adjustPointsMethod === Methods.REMOVE) {
      points.playPoints = values?.playPoints ? -Math.abs(values.playPoints) : 0;
      points.singlesPoints = values?.singlesWinPoints ? -Math.abs(values.singlesWinPoints) : 0;
      points.doublesPoints = values?.doublesWinPoints ? -Math.abs(values.doublesWinPoints) : 0;
    } else if (values?.adjustPointsMethod === Methods.ADD) {
      points.playPoints = values?.playPoints ? Math.abs(values.playPoints) : 0;
      points.singlesPoints = values?.singlesWinPoints ? Math.abs(values.singlesWinPoints) : 0;
      points.doublesPoints = values?.doublesWinPoints ? Math.abs(values.doublesWinPoints) : 0;
    }

    try {
      await addActivityPoints({
        variables: {
          id: {
            identifier: id,
            type: 'UAID',
          },
          input: {
            singlesWinPoints: points.singlesPoints,
            doublesWinPoints: points.doublesPoints,
            timestamp: values?.timestamp,
            ballColour: values?.ballColor,
            playPoints: points.playPoints,
            reason: values?.reason,
          },
        },
      });
      const state: NavigationState = {
        popup: {
          message: t('points adjusted'),
        },
      };
      navigate(`/players/${id}`, { state });
    } catch (error) {}
  };

  const validationSchema = Yup.object().shape({
    timestamp: Yup.string()
      .required(t('is required', { type: 'Date' }))
      .nullable(),
    ballColor: Yup.string()
      .required(t('is required', { type: 'Ball color' }))
      .nullable(),
  });

  const fullName =
    profileData?.personByExternalId?.standardGivenName + ' ' + profileData?.personByExternalId?.standardFamilyName;

  return loadingProfile ? (
    <Spinner />
  ) : (
    <>
      <Breadcrumbs
        paths={[
          { name: t('players'), to: '/players' },
          { name: fullName, to: `/players/${id}` },
          { name: t('adjust points'), active: true },
        ]}
      />

      <H3 spacing={{ margins: { xs: 'bottom' } }}>
        {t('adjust points for', {
          name: fullName,
        })}
      </H3>

      <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
        {({ errors, values, touched }) => (
          <Form>
            <Panel spacing={{ margins: { xs: 'top' } }}>
              <div className={styles.form}>
                <InputLabel className={styles.bold} spacing={{ margins: { lg: 'vertical' } }}>
                  {t('date')}
                </InputLabel>
                <DateField
                  name="timestamp"
                  datePickerProps={{
                    selected: values.timestamp ? new Date(values.timestamp) : new Date(),
                    spacing: { margins: { lg: 'bottom', md: 'right' } },
                  }}
                />
                <MinusTopGrid>
                  {errors?.timestamp && touched?.timestamp && <FormErrorMessage message={errors.timestamp} />}
                </MinusTopGrid>

                <InputLabel>{t('adjust points method')}</InputLabel>
                <DropdownField name={'adjustPointsMethod'} options={methods} spacing={{ margins: { xxs: 'top' } }} />

                <InputLabel spacing={{ margins: { lg: 'top', xxs: 'bottom' } }} className={styles.flex}>
                  {t('ball color')}
                </InputLabel>
                <div className={styles.paddingBottom}>
                  <Field
                    name={'ballColor'}
                    spacing={{ margins: { lg: 'top' } }}
                    as={RadioButtonGroup}
                    options={colors}
                    //value={values?.ballColor}
                  />
                </div>

                <MinusTopGrid>
                  {errors?.ballColor && touched?.ballColor && <FormErrorMessage message={errors.ballColor} />}
                </MinusTopGrid>

                <InputLabel spacing={{ margins: { lg: 'top' } }}>{t('play points')}</InputLabel>
                <TextField
                  type={'number'}
                  name={'playPoints'}
                  value={values?.playPoints}
                  fullWidth
                  spacing={{ margins: { lg: 'bottom', xxs: 'top' } }}
                />

                {values?.ballColor === BallColor.GREEN && (
                  <>
                    <InputLabel spacing={{ margins: { lg: 'top' } }}>{t('singles win points')}</InputLabel>
                    <TextField
                      type={'number'}
                      name={'singlesWinPoints'}
                      spacing={{ margins: { xxs: 'top', lg: 'bottom' } }}
                      fullWidth
                    />

                    <InputLabel spacing={{ margins: { lg: 'top' } }}>{t('doubles win points')}</InputLabel>
                    <TextField
                      type={'number'}
                      name={'doublesWinPoints'}
                      spacing={{ margins: { xxs: 'top', lg: 'bottom' } }}
                      fullWidth
                    />
                  </>
                )}

                <InputLabel spacing={{ margins: { lg: 'top' } }}>{t('reason')}</InputLabel>
                <TextField name={'reason'} multiline rows={8} fullWidth spacing={{ margins: { xxs: 'top' } }} />
              </div>
            </Panel>

            <div className={styles.row}>
              <Button
                size="md"
                spacing={{ margins: { lg: 'top' } }}
                level="tertiary"
                onClick={() => navigate(`/players/${id}`)}
                type="button"
              >
                {t('cancel')}
              </Button>
              <Button size="md" spacing={{ margins: { lg: 'top', sm: 'left' } }} type="submit" loading={loading}>
                {t('save points')}
              </Button>
            </div>
            <APIErrorMessage error={addActivityPointsError && t('add points error')} />
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AdjustPoints;
