import React from 'react';

import { RouteComponentProps, Router } from '@reach/router';
import AdjustPoints from 'src/components/adjust-points/adjust-points';
import Layout from 'src/components/layout/layout';
import SEO from 'src/components/seo';
import { usePrivateRoute } from 'src/utils/auth';

const AdjustPointsPage = () => {
  usePrivateRoute();
  return (
    <Router basepath="/players/:id">
      <AdjustPointsRoute path="/adjust-points" />
    </Router>
  );
};

interface AdjustPointsRouteProps extends RouteComponentProps {
  id?: string;
}

const AdjustPointsRoute: React.FC<AdjustPointsRouteProps> = ({ id }) => {
  return (
    <Layout>
      <SEO title="Adjust points" />
      <AdjustPoints id={id} />
    </Layout>
  );
};

export default AdjustPointsPage;
